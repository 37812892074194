import React, {FC} from "react";
import github from "../assets/github.png";
import twitter from "../assets/twitter.png";
import linkedin from "../assets/linkedin.png";

const Footer: FC = () => {
  return (
    <footer className=" bg-gray-dark p-5 flex flex-row justify-evenly">
      <a href="https://github.com/w-ensink" className="w-20 p-5 bg-white rounded-full"><img src={github} alt="GitHub"/></a>
      <a href="https://twitter.com/wouter_ensink" className="w-20 p-5 bg-white rounded-full"><img src={twitter}
                                                                                                  alt="Twitter"/></a>
      <a href="https://www.linkedin.com/in/wouter-ensink-748b65191/" className="w-20 p-5 bg-white rounded-full"><img src={linkedin}
                                                                                                  alt="LinkedIn"/></a>
    </footer>
  )
}

export default Footer;