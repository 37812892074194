import React, {FC} from "react";
import me from "../assets/me.png";

export const MeImage: FC = () => {
  return (<div className="p-2 m-10 flex flex-col justify-center justify-items-center items-center basis-1/2">
    <img
      className="scale-125 object-cover block w-auto h-auto rounded-full max-h-[250px]"
      src={me}
      alt="Me"
    />
  </div>);
}

export interface IntroducerProps {
  title: string,
  children: any,
  alwaysColumn?: boolean,
}

const Introducer: FC<IntroducerProps> = ({title, children, alwaysColumn}) => {
  const forceCol = alwaysColumn ?? false;
  return (
    <div className={`m-10 flex flex-col ${forceCol ? "" : "md:flex-row"} justify-evenly items-center md:items-start`}>
      <div className="mr-5 basis-1/2">
        <h1 className="mt-2 text-5xl my-1 font-bold">{title}</h1>
        <p className="pt-5 text-xl my-1">
          {children}
        </p>
      </div>
      <MeImage/>
    </div>
  )
}

export default Introducer;