import React, {FC} from "react";
import Introducer from "./Introducer";

const Contact: FC = () => {
  return (
    <Introducer title="Contact me">
      Due to the large amount of email spam I received on my previous website,
      the only way to contact me at the moment is via <a href={"https://twitter.com/wouter_ensink"} className="link">Twitter</a> or <a className={"link"} href={"https://www.linkedin.com/in/wouter-ensink-748b65191/"}>LinkedIn</a>.
      <br/>
      <br/>
      Feel free to send me a DM!
    </Introducer>
  );
};

export default Contact;