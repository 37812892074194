import React, {FC, useEffect, useState} from "react";
import Introducer from "./Introducer";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import blog from "../assets/blog.md";
import blog2 from "../assets/blog2.md";
import './github-markdown.css';
import {Link, useParams} from "react-router-dom";

interface Post {
  title: string,
  author: string,
  date: string,
  file: string,
  url: string,
  description: string,
}

const getBlogFiles = () => {
  const p1: Post = {
    title: "Timur Doumler on TDD for Audio (interview)",
    author: "Wouter Ensink",
    date: "02.09.2022",
    file: blog,
    url: "timur-tdd-interview",
    description: "I interviewed Timur Doumler about TDD for audio programming purposes."
  };

  const p2: Post = {
    title: "Paper",
    author: "Wouter Ensink",
    date: "05.10.2022",
    file: blog2,
    url: "paper",
    description: "Some of my thoughts about test driven development."
  };
  return [p1];
};


export const BlogPost: FC = () => {
  const [post, setPost] = useState<Post | undefined>(undefined);
  const [postContent, setPostContent] = useState("");
  const params = useParams();

  useEffect(() => {
    const post = getBlogFiles().find((p) => p.url == params.post);
    setPost(post);
    fetch(post?.file as unknown as URL)
      .then(res => res.text())
      .then(text => setPostContent(text))
  }, [])

  return (
    <div className="m-10">
      <div className="mb-5">
        <p className="py-1 px-4 border-solid border w-fit mb-5 hover:bg-gray-light rounded-xl">
          <Link to="/blog">
            🔙 All blog posts
          </Link>
        </p>
        <div className="flex flex-col md:flex-row justify-between">
          <p className="py-1">📝 Author: {post?.author}</p>
          <p className="py-1">🗓 Date: {post?.date}</p>
        </div>
      </div>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        className="markdown-body">
        {postContent}
      </ReactMarkdown>
    </div>
  )
}

interface BlogPostPreviewProps {
  author: string,
  date: string,
  title: string,
  link: string,
  children: any,
}

const BlogPostPreview: FC<BlogPostPreviewProps> = ({author, date, title, link, children}) => {
  return (
    <Link to={link}>
      <div className="m-10 p-5 border-solid border rounded-xl hover:bg-gray-light">
        <h1 className="text-2xl">{title}</h1>
        <p className="my-1">
          {children}
        </p>
        <div className="flex flex-col sm:flex-row justify-between mt-5">
          <p className="text-xs py-1 sm:py-0">📝 Author: {author}</p>
          <p className="text-xs pt-1 sm:py-0">🗓 Date: {date}</p>
        </div>
      </div>
    </Link>
  )
};

const Blog: FC = () => {
  const previews = getBlogFiles().map(b => (
    <BlogPostPreview author={b.author} date={b.date} title={b.title} link={b.url}>
      {b.description}
    </BlogPostPreview>));

  return (
    <div>
      <Introducer title="Welcome to my blog">
        Here you'll find some blog posts about subjects
        that I find interesting and important.
      </Introducer>
      {previews}
    </div>
  );
};

export default Blog;