import React, {FC} from "react";
import {portfolioItems} from "./AllPortfolioItems";
import Introducer from "./Introducer";

function PortfolioIntro() {
  return (
    <Introducer title="Some of my work">
      Below you will find a collection of some of the work I've made over
      the past few years.
      <br/>
      <br/>I hope you like it!
    </Introducer>
  );
}

const Portfolio: FC = () => {
  const items = portfolioItems.map(i => {
    return (
      <React.Fragment>
        <div className="mx-10 bg-gray h-px border-solid border-t border-gray-dark "></div>
        {i}
      </React.Fragment>
    )
  });

  return (
    <div>
      <PortfolioIntro/>
      {items}
    </div>
  );
};

export default Portfolio;