import PortfolioItem from "./PortfolioItem";
import end_boost from "../end_boost.png";
import playguru from "../assets/guru2.png";
import mikoto_studio from "../mikoto.png";

const playGuru = (
  <PortfolioItem title={"PlayGuru"} image={playguru}>
    Together with Marc Groenewegen I'm building a mobile application for Android and iOS that helps guitarists improve
    their skills.
    <br/>
    <br/>
    The application listens to how the guitarist plays and adapts the level of the exercises based on that.
    <br/>
    <br/>
    My role is multidisciplinary and includes:
    <ul>
      <li> - Digital Signal Processing</li>
      <li> - Music Information Retrieval</li>
      <li> - Software Architecture Design</li>
      <li> - User Interface Design using the Flutter Framework</li>
      <li> - Web Design</li>
      <li> - Interaction Design</li>
    </ul>
    <br/>
    You can check out PlayGuru on <a className={"link"} href={"https://playguru.app"}>its official website</a>!
  </PortfolioItem>
);

const mikoto = (
  <PortfolioItem title={"Mikoto Studio"} image={mikoto_studio}>
    Mikoto Studio is a revolutionary new singing synthesizer and digital audio workstation developed by <a
    href={"https://expressivelabs.net"} className={"link"}>Expressive Labs</a> that aims to provide a modern and more intuitive workflow to synthesize vocal performances.
    <br/>
    <br/>
    On this project I often helped out with software architecture and audio related tasks.
    I setup the modern software stack, consisting of Rust for the engine and Flutter for the user
    interface.
    You can checkout Mikoto Studio on the <a className={"link"} href={"https://mikoto.studio"}>official website</a>!
  </PortfolioItem>
)

const endBoost = (
  <PortfolioItem title={"End Boost"} image={end_boost}>
    End Boost is a new innovative application for macOS and Windows, developed by <a href={"https://unimule.com"}
                                                                                     className={"link"}>Unimule</a>,
    that helps video editors mixing their audio by automating the process for them
    using intelligent algorithms.
    <br/>
    <br/>
    I worked on this project as a freelance C++ software developer.
    Things I worked on include the audio engine,
    cross platform video preview and user interface.
    You can check out End Boost on <a className={"link"} href={"https://www.endboost.com"}>its official website</a>
  </PortfolioItem>
);

const whisperingShadows = (
  <PortfolioItem
    title="CSD3 - Whispering Shadows"
    videoUrl="https://www.youtube.com/embed/YysBLwLQ73g"
  >
    Whispering Shadows is a project that I did together with Paul Wienk, Daniël
    Kamp & Nino Saglia.
    <br/>
    It was done for Creative Coding Utrecht (CCU).
    <br/>
    <br/>
    The system works based on a camera that films a display. The image is then
    projection mapped and displayed on that same display. This creates a near
    perfect feedback loop. Everything in between the camera and the display
    creates sort of a shadow effect. The image is analyzed and processed before
    it gets displayed again. The features detected in the image are sent to the
    audio system, where sound is created that gives a great sense of interaction
    to the user.
  </PortfolioItem>
);

const gameAudioShooter = (
  <PortfolioItem
    title="Game Audio - Shooter"
    videoUrl="https://www.youtube.com/embed/whMuP2fitDs"
  >
    This is a project where I created the audio for a shooter (game) together
    with Paul Wienk.
    <br/>
    <br/>
    We were aiming for a kind of realistic, but larger than life experience.
    <br/>
    For this project I focussed on the sound design and music composition, while
    Paul was more focussing on the integration part with the game.
  </PortfolioItem>
);

const levoDimensions = (
  <PortfolioItem
    title="Levo - Dimensions"
    videoUrl="https://www.youtube.com/embed/caoldsKlGJE"
  >
    Levo is a project that I did together with 3 other students when I made the
    jump from the first half of year 2 of the study to the second half of year
    3.
    <br/>
    <br/>
    The product on which we worked was called Dimensions and was a polyphonic
    Karplus Strong synthesizer with external input.
    <br/>
    The synth was running bare metal C++ code on a Daisy Seed micro controller.
    Apart from the controls on the front panel, it had a rich set of features
    that could be controlled via the display on the front, including various
    modulation options.
    <br/>
    <br/>
    This resulted in a very powerful system with many options to customize the
    sound, while not being overly complicated to use.
    <br/>
    <br/>
    Part of the credits go to Freek Heldens, Eric Schuster & Shiva Bindraban.
  </PortfolioItem>
);

const callOfTheCars = (
  <PortfolioItem
    title="Call Of The Cars"
    videoUrl="https://www.youtube.com/embed/yzPR-gd8tzc"
  >
    Call of the Cars is a game that I developed the audio for.
    <br/>
    <br/>
    It was a project for school where we had to work together with a team of
    game developers.
    <br/>
    <br/>
    The idea is that you're a self driving car that has been discarded due to an
    accident that you caused. After that you'll be dropped on a scrape yard and
    you need get away from all the dangers there. You need to avoid being
    crushed at the conveyor belt and you need to run/drive away from other self
    driving cars with the same destination.
    <br/>
    <br/>
    Part of the credits go to Anna Lubbers, Niki van der Kuil, Robbert Leenen &
    Thomas van Eekelen.
  </PortfolioItem>
);

const whoAmAI = (
  <PortfolioItem
    title="Who Am AI?"
    videoUrl="https://www.youtube.com/embed/ldQQaz5eks0"
  >
    A voice controlled game where you guess who your opponent is.
    <br/>
    <br/>
    Every face is generated with “thispersondoesnotexist.com”
    <br/>
    <br/>
    The music is generated based on the persons that are still visible at your
    opponent's side. When your opponent has few faces left, it means he/she is
    closer to guessing your character and therefore this makes the music go
    faster.
    <br/>
    <br/>
    All user input is done via speech recognition, using the Google api. The
    whole project is written in Python and is freely available on my GitHub
    page.
    <br/>
    <br/>
    Part of the credits go to Paul Wienk with whom I made this.
  </PortfolioItem>
);

const embeddedSnakes = (
  <PortfolioItem
    title="Embedded Multi-Player Snakes"
    videoUrl="https://www.youtube.com/embed/gKvBkadHlHg"
  >
    Snake, but made multiplayer. Running on an embedded chip, called the Teensy.
    Complete with sound effects, running as a standalone device.
    <br/>
    <br/>
    The source code is openly available on my GitHub page.
    <br/>
    <br/>
    Part of the credits go to Paul Wienk with whom I made this.
  </PortfolioItem>
);

const consoleSynthesizer = (
  <PortfolioItem
    title="Console Synthesizer"
    videoUrl="https://www.youtube.com/embed/K4TrhOPK66U"
  >
    A console synthesizer, made with JUCE in C++. It's rich on features,
    supporting FM & RM synths, undo/redo functionality, MIDI support, melody
    generation, playback & editing and more.
    <br/>
    <br/>
    Super cool and free & open source!
    <br/>
    <br/>
    The project can be found on <a href={"https://github.com/w-ensink/CSD2/tree/master/CSD2b/console_synth"} className={"link"}>my GitHub page</a>.
  </PortfolioItem>
);

const floatingIntoReality = (
  <PortfolioItem
    title="Floating Into Reality"
    videoUrl="https://www.youtube.com/embed/8nJEWKugijE"
  >
    In the first section of the video, the sounds are generated using the chaos
    of floating point rounding errors, staying completely in the digital domain.
    <br/>
    <br/>
    In the second half, the space dimension is added, bringing it into the real
    world. This parts stays with the “floating point” theme, where the red dot
    is the “floating point”. The position of the floating point within space is
    mapped to musical parameters like the pitch and panning.
    <br/>
    <br/>
    Part of the credits go to Paul Wienk with whom I made this.
  </PortfolioItem>
);

const letMeGrabSomePopcorn = (
  <PortfolioItem
    title="Let Me Grab Some Popcorn"
    videoUrl="https://www.youtube.com/embed/9T-jATr_78c"
  >
    This was one of the video's I supplied when I submitted myself for the
    University of the Arts in Utrecht.
    <br/>
    <br/>
    It's a robot arm modulating the sounds of popcorn, while picking up popcorn
    and dropping it into a bucket.
    <br/>
    <br/>
    Everything, from the robot arm, the controlling software and the Pure Data
    patch to even the low level sampler (in C++) that played back the poppy
    sounds, was self made.
  </PortfolioItem>
);

export const portfolioItems = [
  playGuru,
  mikoto,
  endBoost,
  levoDimensions,
  embeddedSnakes,
  consoleSynthesizer,
  whisperingShadows,
  gameAudioShooter,
  callOfTheCars,
  whoAmAI,
  floatingIntoReality,
  letMeGrabSomePopcorn,
];