import React, {useState} from "react";
import {Link} from "react-router-dom";
import Hamburger from 'hamburger-react';

export interface NavBarItemProps {
  name: string,
  linkTo: string,
}

export const NavBarItem: React.FC<NavBarItemProps> = (props) => {
  return (
    <Link to={props.linkTo}
          className="p-5 m-2 flex flex-col justify-center hover:bg-white hover:rounded-2xl hover:text-gray-dark">
      <div>
        {props.name}
      </div>
    </Link>
  )
}

export interface NavBarProps {
  children: JSX.Element[];
}

const NavBar: React.FC<NavBarProps> = (props) => {
  const [menuExpanded, setMenuExpanded] = useState(false);
  const itemsStyle = `${menuExpanded ? "flex" : "hidden sm:flex"}`;

  const children = props.children.map(c => (<div onClick={() => setMenuExpanded(false)}>{c}</div>));

  return (
    <nav className="md:px-10 bg-gray-dark text-white flex flex-col md:flex-row flex-nowrap justify-between items-center">
      <Link to="/" className="p-1 m-4">
        <h1 className="font-normal text-3xl ">
          Wouter Ensink
        </h1>
        <p className={"text-center"}>Audio Software Developer</p>
      </Link>
      <div className="sm:hidden pb-2">
        <Hamburger toggled={menuExpanded} onToggle={open => setMenuExpanded(open)}/>
      </div>
      <div className={`flex-col sm:flex-row flex-nowrap justify-around items-center ${itemsStyle}`}>
        {children}
      </div>
    </nav>
  )
}

export default NavBar;