import React from 'react';
import NavBar, {NavBarItem} from "./components/NavBar";
import About from "./components/About";
import Footer from "./components/Footer";
import Portfolio from "./components/Portfolio";
import {Route, Routes} from "react-router-dom";
import Blog, {BlogPost} from "./components/Blog";
import Contact from "./components/Contact";

function App() {
  return (
    <div className="bg-white flex flex-col min-h-screen">
      <NavBar>
        <NavBarItem name="About" linkTo="/"/>
        <NavBarItem name="Portfolio" linkTo="/portfolio"/>
        <NavBarItem name="Blog" linkTo="/blog"/>
        <NavBarItem name="Contact" linkTo="/contact"/>
      </NavBar>
      <div className="self-center flex-grow max-w-screen-xl">
        <Routes>
          <Route path="/" element={<About/>}/>
          <Route path="/portfolio" element={<Portfolio/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/blog" element={<Blog/>}/>
          <Route path="/blog/:post" element={<BlogPost/>}/>
          <Route path="/contact" element={<Contact/>}/>
        </Routes>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
