import React from "react";
import "../video.css";

interface YoutubeVideoProps {
  url: string;
}

const VideoEmbed = ({url}: YoutubeVideoProps) => {
  return (
    <div
      className={"lg:ml-5 w-full h-full lg:p-4 flex flex-col items-center  sm:min-h-[350px] min-h-[200px] sm:min-w-[400px] md:min-w-[500px] min-w-[300px]"}>
      <div className="container rounded-2xl">
        <iframe
          className="responsive-iframe"
          src={url}
          title="YouTube video player"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

interface PortfolioItemProps {
  title: string;
  videoUrl?: string;
  image?: string;
  children: any;
}

const PortfolioItem: React.FC<PortfolioItemProps> = (props) => {
  return (
    <div className="mt-10 mx-5 md:mx-10 lg:mb-10 flex flex-col lg:flex-row items-center">
      <div className="mr-5 basis-1/2 self-start">
        <h1 className="text-3xl font-bold">{props.title}</h1>
        <p className="text-xl mt-5">{props.children}</p>
      </div>
      <div className="md:mt-0 basis-1/2 flex flex-col justify-center items-center pt-10 lg:pt-0">
        {props.videoUrl && <VideoEmbed url={props.videoUrl}/>}
        {props.image && <img className={"max-h-96 px-5 mb-10"} src={props.image} alt=""/>}
      </div>
    </div>
  );
};

export default PortfolioItem;