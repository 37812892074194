import React, {FC, ReactNode} from "react";
import {MeImage} from "./Introducer";

interface TitleProps {
  children: ReactNode;
}

const SubTitle = ({children}: TitleProps) => {
  return (<span className={"text-xl font-bold "}>
      <br/>
      <br/>
    {children}
    <br/>
  </span>);
}

const Title = ({children}: TitleProps) => {
  return (<span className={"text-3xl font-bold"}>
      <br/>
    {children}
    <br/>
  </span>);
}

const About: FC = () => {
  return (
    <div className={"px-5 sm:px-10 py-10 max-w-screen-lg text-xl"}>
      <div className={"text-5xl font-bold"}>A little bit about myself</div>
      <div className={"flex lg:flex-row flex-col "}>
        <div className={"basis-1/2"}>
          <div className={"pt-10 basis-1/2 self-center block lg:hidden"}>
            <MeImage></MeImage>
          </div>
          <Title>Interests</Title>
          My interests have always been focussed around creating things. When I was younger I would always be making
          something. This ranged from building an electric cart together with my grandfather to building a tree house.

          As I grew older I got interested in making electronic music, drawing inspirations from the big Dutch and
          Swedish
          DJs. I started making my own music and ended up going to the <a href={"https://hku.nl/en"} className={"link"}>University
          of the Arts in Utrecht</a>, studying Music
          &
          Technology.

          Funny enough during this course I found myself making less and less music and instead developing my other
          interest: programming.
          I already had some experience using C++, but at this point I really started getting into it.
          This soon led me to build bigger and bigger projects, some of which can be found on my portfolio page.

          My core interest lay round software architecture, user experience design and digital signal processing.
          <br/>
        </div>
        <div className={"basis-1/2 self-center hidden lg:block"}>
          <MeImage></MeImage>
        </div>
      </div>
      <Title>Experience</Title>
      Over the years I've gained a lot of experience with different technologies during different projects. Below I've
      listed some of my most notable experiences.

      <SubTitle>Releasing Software</SubTitle>
      During my time working on <a href={"https://endboost.com"} className={"link"}>End Boost</a> I got my first taste at what it's like to release software into the real
      world. I came to realize it takes a lot more than meets the eye at first. More recently I took this experience
      and
      started releasing my own application in collaboration with Marc Groenewegen, <a href={"https://playguru.app"} className={"link"}>PlayGuru</a>, into the mobile app
      stores.

      <SubTitle>C++</SubTitle>
      I have about 7 years of experience with the C++ programming language and have used it for a lot of projects,
      ranging from embedded synthesizers to an automatic audio mixing DAW, End Boost.
      Within C++ I've used some libraries and frameworks, including JUCE, Catch2 and fmtlib.

      <SubTitle>Rust</SubTitle>
      About 4 years ago I started exploring the Rust programming language, seeing if it could replace C++ for me. Soon
      I
      found out it was a much nicer and safer programming language, so I started using it for more and more projects.
      Right now I use it for my main project, PlayGuru.

      <SubTitle>Flutter</SubTitle>
      As soon as the Flutter framework became available for desktop I started using it for a podcast production app
      that
      ultimately never saw the light of day. I did however use this experience to use it in my main project, PlayGuru.

      <SubTitle>Git</SubTitle>
      For version control I've always used Git in my projects and thus I've gained a fair bit of experience with it
      over
      the years. I've familiarized myself with some additional workflows too, like pull requests, continuous
      integration
      and trunk based development.

      <SubTitle>Other</SubTitle>
      In order to not make this page incredibly long, here are some other languages and tools I'm also familiar with:
      <ul>
        <li> - Python</li>
        <li> - TypeScript</li>
        <li> - React</li>
        <li> - SQLite</li>
        <li> - Swift</li>
        <li> - Unity</li>
      </ul>

      <Title>Education</Title>
      I've completed the Audio Design course at University of the Arts Utrecht, which includes programming courses and
      interaction design courses, mainly focussed around audio.
    </div>
  )
}

export default About;